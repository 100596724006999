export const getAchievementReducer = (state = {}, action) => {
    switch (action.type) {
        case 'GET_ACHIEVEMENTS':
            return {
                ...state,
                achievements: action.payload,
                loading: false
            }
        case 'UPDATE_ACHIEVEMENT':
            const {locationId, platform, newSource} = action.payload
            return{
                ...state,
                achievements: {
                    ...state.achievements,
                    data: state.achievements.data.map((location) => 
                        location.id == locationId
                        ?
                        {
                            ...location,
                            sources: {
                                ...location.sources,
                                [platform]: newSource
                            }
                        }
                        : location
                    )
                }
            }
        case 'LOADING_ACHIEVEMENTS':
            return{
                ...state,
                loading: true
            }
        default:
            return state;
    }
}