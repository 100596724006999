import React from "react";

const SuccessSubscriptionModal = ({ success_text, setOpenSuccess }) => {
    return (
        <div className="wrapper-loc-edit-cart subscription-edit-card close_subscription_modal success_modal_subscription">
            <div className="cart-details-container">
                <div className="cart-header-wrapper">
                    <img src="/admin-icons/kore.png" alt="Kore" />
                </div>

                <div className="content-modal-close">
                    <img src="/admin-icons/success_icon.svg" alt="success" />
                    <h3>{success_text.title}</h3>
                    <p>{success_text.text}</p>
                    <p>{success_text.text2}</p>
                    <p>{success_text.text3}</p>
                </div>

                <div className="card-details-footer">
                    <button className="confirm-btn" onClick={setOpenSuccess}>OK</button>
                </div>
            </div>
        </div>
    )
}
export default SuccessSubscriptionModal;