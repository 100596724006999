import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { MdOutlineClear } from "react-icons/md"
import { Grid, Skeleton, Alert, Modal } from "@mui/material"
import { fetchWallet, clearWallet } from "../../../../redux/actions/admin-index"
import { baseUrlDev } from "../../../Helpers/baseUrl"
import axios from "axios"
import { getLocalUser } from "../../../TokenControl/parts/useLocalUser"
import { useTranslation } from "react-i18next"
import CloseEditCard from "../../Components/Subscription/closeEditCard"
import ConfirmModal from "../../helpers/confirmModal"
import PaymentCard from "../../Components/PaymentCard/PaymentCard"
import { AddCard } from "../../../../redux/actions/admin-index"

const WalletDetails = ({ setOpen }) => {
    const [openPayment, setOpenPayment] = useState(false)
    const dispatch = useDispatch()
    const [errors, setErrors] = useState([])
    const [success, setSuccess] = useState()
    const [disabled, setDisabled] = useState(false)
    const [custom, setCustom] = useState("")
    const [amount, setAmount] = useState()
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false)
    const { t } = useTranslation()

    useEffect(() => {
        dispatch(fetchWallet())
    }, [])

    const wallet = useSelector(state => state.getWalletReducer.wallet)

    const save = (card) => {
        let callback = () =>{
            dispatch(AddCard(card))
        }
        postDepositeReq(card.id, callback)
    }

    const edit = (card) => {
        postDepositeReq(card.id)
    }

    const onDelete = () => {
        setOpenPayment(false)
    }

    const postDepositeReq = (id, callback) => {
        axios.post(`${baseUrlDev}wallet/deposit`, {amount: parseInt(amount), card_id: id}, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })
            .then((res) => {
                setOpenPayment(false)
                setDisabled(false)
                setLoading(false);
                callback && callback()
                if (!res.data.status) {
                    setErrors(errors => [...errors, res.data.message ? res.data.message : "Something went Wrong!"])
                    setTimeout(() => {
                        setErrors([])
                    }, 5000)
                } else {
                    setSuccess(res.data.message)
                    setLoading(false);
                    dispatch(fetchWallet())
                    setTimeout(() => {
                        setSuccess("")
                        setOpen()
                    }, 5000)
                }
            }).catch(() => {
                setOpenPayment(false)
                setLoading(false);
            })
    }

    const addDeposite = (amount, type) => {
        setOpenConfirmModal(true);
        setAmount(amount ?? custom ?? 0)
    }

    const handleCustomChange = (e) => {
        const value = e.target.value;
        const numericValue = value.replace(/[^0-9]/g, '');
        let formattedValue;

        if (numericValue.startsWith('0') && numericValue.length > 1) {
            if (numericValue.length === 2) {
                formattedValue = `0.${numericValue.slice(-1)}`;
            } else {
                formattedValue = `${numericValue.slice(0, -2)}.${numericValue.slice(-2)}`;
            }
        } else {
            formattedValue = numericValue;
        }

        setCustom(formattedValue);
    };

    const closeConfirmModal = () => {
        setOpenConfirmModal(false);
    }

    const confirmRequestHandler = () => {
        setOpenPayment(true)
        setOpenConfirmModal(false);
    }
    const success_text = {
        title: "Deposit Added Succefully!",
    };
    
    const onSaveError = (err) => {
        setLoading(false)
        err?.response?.data.errors ?
            Object.keys(err.response.data.errors).map(key => {
                setErrors(errors => [...errors, err.response.data.errors[key][0]])
            })
            :
            setErrors(["Something Went Wrong"])
        setTimeout(() => {
            setErrors([])
        }, 5000)
    }
    return (
        <div className="modal-wallet-container">
            <PaymentCard
                show_cards={true}
                open={openPayment}
                setOpen={() => setOpenPayment(false)}
                onSave={save}
                onEdit={edit}
                loading={loading}
                setLoading={setLoading}
                onDelete={onDelete}
                openSuccess={openSuccess}
                setOpenSuccess={setOpenSuccess}
                success_text = {success_text}
                onSaveError={onSaveError}
            />
            <Modal
                open={openConfirmModal}
            >
                <div>
                    <ConfirmModal
                        title={`Adding $${amount} to your credits`}
                        subTitle="Are you sure you want to add this amount?"
                        closeConfirmModal={closeConfirmModal}
                        confirmRequestHandler={confirmRequestHandler}
                        loading={loading}
                    />
                </div>
            </Modal>
            {errors && errors.map(err => {
                return (
                    <div className="alert-inside-modal" key={err}>
                        <Alert severity="error" className="custom-error-alert">{err}</Alert>
                    </div>
                )
            })}
            {success &&
                <div className="alert-inside-modal">
                    <Alert severity="success">{success}</Alert>
                </div>
            }
            <div className="modal-wallet-top">
                <img src={"/admin-images/koreco_copyright.svg"} width="220" alt="logo" />
                <span onClick={() => setOpen()}><MdOutlineClear /></span>
            </div>
            <div className="modal-wallet-header">
                <div className="modal-wallet-header-left">
                    <h3>{t("Add Credit")}</h3>
                    <p>{t("Choose Amount")} <span>(USD)</span></p>
                </div>
                <div className="modal-wallet-header-right">
                    <span>{t("Current Credit")}</span>
                    {(wallet === undefined || !wallet.wallet) ? <Skeleton style={{ height: "28px", transform: "unset" }} /> : <h4>${wallet.wallet.balance}</h4>}
                </div>
            </div>
            <div className="modal-wallet-content custom-scrollbar-vertical">
                <div className="modal-wallet-body">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={3}>
                            <div className="wallet-body-box">
                                <span>$10</span>
                                <small>{t("Credits")}</small>
                                <button disabled={disabled} className={disabled ? "disabled-wallet-btn" : ""} onClick={() => addDeposite(10, "default")}>{t("Continue to add")} $10</button>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <div className="wallet-body-box">
                                <span>$15</span>
                                <small>{t("Credits")}</small>
                                <button disabled={disabled} className={disabled ? "disabled-wallet-btn" : ""} onClick={() => addDeposite(15, "default")}>{t("Continue to add")} $15</button>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <div className="wallet-body-box">
                                <span>$25</span>
                                <small>{t("Credits")}</small>
                                <button disabled={disabled} className={disabled ? "disabled-wallet-btn" : ""} onClick={() => addDeposite(25, "default")}>{t("Continue to add")} $25</button>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <div className="wallet-body-box-custom">
                                <div>
                                    <span>$</span>
                                    <input type="text" value={custom ?? ""} onChange={handleCustomChange} placeholder="" />
                                </div>
                                <small>{t("Add Custom Amount")}</small>
                                <button disabled={disabled} className={disabled ? "disabled-wallet-btn" : ""} onClick={() => addDeposite(null, "custom")}>{t("Continue to add")} {custom > 0 && `to add $${custom}`}</button>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div className="modal-wallet-summary">
                    <h2>{t("Summary")}</h2>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={4}>
                            <div className="wallet-box-summary wallet-box-summary-added">
                                <div>
                                    <span><img src={"/admin-icons/file-icon.svg"} alt={"total"} /></span>
                                    <h4>{t("Total added")}</h4>
                                </div>
                                {wallet === undefined ? <Skeleton style={{ height: "28px", transform: "unset" }} /> : <h6>${wallet?.wallet?.total_deposits ?? 0}</h6>}
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <div className="wallet-box-summary wallet-box-summary-spent">
                                <div>
                                    <span><img src={"/admin-icons/dollar-sign.svg"} alt="spent" /></span>
                                    <h4>{t("Total spent")}</h4>
                                </div>
                                {wallet === undefined ? <Skeleton style={{ height: "28px", transform: "unset" }} /> : <h6>${wallet?.wallet?.total_expenses ?? 0}</h6>}
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <div className="wallet-box-summary wallet-box-summary-average">
                                <div>
                                    <span><img src={"/admin-icons/open-f.svg"} alt="file" /></span>
                                    <h4>{t("Monthly average")}</h4>
                                </div>
                                {wallet === undefined ? <Skeleton style={{ height: "28px", transform: "unset" }} /> : <h6>${wallet?.wallet?.average_expenses ?? 0}</h6>}
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div className="modal-wallet-bottom">
                <p>
                    <img src={"/admin-icons/lock.svg"} alt="lock" />{t("Secured by iPaymer")}
                </p>
            </div>
        </div>
    )
}
export default WalletDetails