export const getCardImage = (type) => {
    switch (type) {
        case 'Visa':
            return '/admin-icons/new-visa-icon.svg';
        case 'Mastercard':
            return '/admin-icons/mastercard.svg';
        case 'Amex':
            return '/admin-icons/amex.svg';
        case 'Maestro':
            return '/admin-icons/maes.svg';
        case 'Discover':
            return '/admin-icons/discover.svg';
        default:
            return '/admin-icons/unkown_card.svg';
    }
};