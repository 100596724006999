import React, {useState, useEffect} from "react"
import { Modal } from "@mui/material"
import Card from "./Card"
import ClosePaymentCard from "./ClosePaymentCard"
import { useDispatch, useSelector } from "react-redux"
import { fetchCards } from "../../../../redux/actions/admin-index"
import SuccessSubscriptionModal from "../Subscription/successSubscriptionModal"

const PaymentCard = ({
    onSave, 
    onEdit,
    onSaveError,
    open, 
    setOpen, 
    onDelete, 
    loading, 
    setLoading, 
    show_cards, 
    openSuccess, 
    setOpenSuccess,
    success_text,
    selected
}) => {
    const dispatch  = useDispatch()
    const [openConfirmClose, setOpenConfirmClose] = useState(false)

    const cards = useSelector(state => state.cardsReducer.cards)

    useEffect(() => {
        if(!cards){
            dispatch(fetchCards())
        }
    },[])

    const handleClose = () => {
        setOpenConfirmClose(true)
    }

    const closeConfirmModal = () => {
        setOpen(false);
        setOpenConfirmClose(false);
        setLoading(false)
    };
    
    const closeSuccessModal = () => {
        setOpenSuccess(false);
        setOpen(false);
    }
    
    return(
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-wallet-title"
                aria-describedby="modal-wallet-description"
            >
                {openConfirmClose ? 
                     <div>
                       <ClosePaymentCard 
                        cancelConfirmModal={() => setOpenConfirmClose(false)}
                        closeConfirmModal={closeConfirmModal}
                    />
                    </div>
                : openSuccess ?
                   <div>
                     <SuccessSubscriptionModal setOpenSuccess={closeSuccessModal} success_text={success_text}/>
                   </div>
                :
                <div>
                    <Card 
                        setOpen={handleClose}
                        cards={cards?.data}
                        onSave={onSave}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        loading={loading}
                        onSaveError={onSaveError}
                        setLoading={setLoading}
                        show_cards={show_cards}
                        setOpenSuccess={setOpenSuccess}
                        selected={selected}
                    />
                </div>

                }
            </Modal>
        </div>

    )
}
export default PaymentCard