import React, { Suspense, useEffect, useRef, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import { paths } from "./components/Admin/helpers/sidebarElements";
import i18n from "./i18n";
import { useSelector, useDispatch } from "react-redux";
import { checkDemo, checkCancel, fetchPermissions, clearFetchPermissions, fetchNotifications } from "./redux/actions/admin-index";
import Layout from "./Layout";
import Home from "./components/Home/Home";
import { Login } from "./components/Login";
import HomeOrigin from "./components/HomeOrigin/HomeOrigin";
import About from "./components/About/About";
import Category from "./components/Categories/Category";
import PageNotFound from "./components/PageNotFound";
import ScrollToTop from "./components/Helpers/scrollToTop";

import AccreditationSignUp from "./components/Accredtiation/accreditation-signup";
import SignUp from "./components/SignUp/SignUp";
import LocationForm from "./components/SignUp/locationForm";
import FindKeywords from "./components/FindEngine/FindKeywords";
import TokenControl from "./components/TokenControl/TokenControl";
import UnAuthorize from "./components/UnAuthorized";
import ThankYou from "./components/Survey/Client/thankYouPage";
import TermsConditions from "./components/TermsConditions";
import LogOut from "./components/Logout";
import Flagging from "./components/Admin/Flagging/Flagging"

import "../src/assets/admin.css";
import "../src/assets/app.css";
import "../src/assets/listing.css";
import "../src/assets/style.css";
import '../src/assets/table.css';
import '../src/assets/reviews.css';
import '../src/assets/analysis.css';
import '../src/assets/information.css';
import '../src/assets/social.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import '../src/assets/social.css';
import SocialMediaStats from "./components/Admin/Social/SocialMediaStats";
import ScheduleReviews from "./components/Admin/Social/ScheduleReviews";
import PublishToAccounts from "./components/Admin/Social/PublishToAccounts";
import Schedules from "./components/Admin/Social/Schedules";
import ConnectionsSocial from "./components/Admin/Social/ConnectionsSocial";
// import Locked from "./components/Locked/Locked";
import ScrollToTopCircle from "./components/Admin/helpers/ScrollToTopCircle";

// Lazy-load all components with React.lazy
// Admin
const AutopilotAi = React.lazy(() => import("./components/Admin/Components/AutoPilot/AutopilotAi"));
const SettingsAutopilot = React.lazy(() => import("./components/Admin/Components/AutoPilot/SettingsAutopilot"));
const Admin = React.lazy(() => import("./components/Admin/Components/Dashboard/admin"));
const Reviews = React.lazy(() => import("./components/Admin/Components/ManageReviews/reviews"));
const Tags = React.lazy(() => import("./components/Admin/Components/tags"));
const Sources = React.lazy(() => import("./components/Admin/Components/source"));
const Review = React.lazy(() => import("./components/Admin/Components/ReviewDetails/review"));
const Account = React.lazy(() => import("./components/Admin/Components/Account/account"));
const UserManagement = React.lazy(() => import("./components/Admin/Components/Account/userManagement"));
const ReviewPage = React.lazy(() => import("./components/Admin/Components/Tools/Reviews/ReviewPage"));
const Reputation = React.lazy(() => import("./components/Admin/Components/Locations/Reputation/Reputation"));
const Competitor = React.lazy(() => import("./components/Admin/Components/Competitors/competitor"));
const AnalysisReport = React.lazy(() => import("./components/Admin/Components/Analysis_Report/AnalysisReport"));
const NewAnalysisReport = React.lazy(() => import("./components/Admin/Components/New_Analysis_Report/AnalysisReport"));
const NewAnalysisReport2 = React.lazy(() => import("./components/Admin/Components/New_Analysis_Report/AnalysisReport2"));
const QrCode = React.lazy(() => import("./components/Admin/Components/Tools/QrCode/QrCode"));
const Invoices = React.lazy(() => import("./components/Admin/Components/Account/Invoices/invoices"));
const Subscription = React.lazy(() => import("./components/Admin/Components/Subscription/subscription"));
// Survey
const Forms = React.lazy(() => import("./components/Survey/forms"));
const FormDetails = React.lazy(() => import("./components/Survey/form-details"));
const Form = React.lazy(() => import("./components/Survey/Client/form"));
const ProtectedRoute = React.lazy(() => import("./components/Helpers/protectedRoute"));
const Frame = React.lazy(() => import("./components/Admin/Components/Dashboard/frame"));
const Notifications = React.lazy(() => import("./components/Admin/Components/Account/notifications"));
const AccountAddUser = React.lazy(() => import("./components/Admin/Components/Account/accountAddUser"));
const AddUser = React.lazy(() => import("./components/Admin/Components/Account/AddUser"));
const LocationSettings = React.lazy(() => import("./components/Admin/Components/Locations/LocationInformation/LocationSettings"));
const LocationSettingsEdit = React.lazy(() => import("./components/Admin/Components/Locations/LocationInformation/LocationSettingsEdit"));
const Listing = React.lazy(() => import("./components/Admin/Components/Locations/Listing/Listing"));
const SourceScore = React.lazy(() => import("./components/Admin/Components/Locations/Listing/SourceScore"));
const CouponList = React.lazy(() => import("./components/Admin/Components/Tools/Coupons/CouponList"));
const Banner = React.lazy(() => import("./components/Admin/parts/banner"));
const Connections = React.lazy(() => import('./components/Admin/Components/Connections/Connections'));
const ConnectionsSettings = React.lazy(() => import('./components/Admin/Components/Connections/ConnectionsSettings'));
const RevuShare = React.lazy(() => import("./components/Admin/Components/Tools/RevuShareWeb/RevuShare"));
const GroupPage = React.lazy(() => import("./components/Admin/Components/Tools/Group-Page/GroupPage"));
const QuickReplies = React.lazy(() => import("./components/Admin/Components/Tools/QuickReplies/QuickReplies"));
const Support = React.lazy(() => import("./components/Admin/Components/Support/Support"));

const Social = React.lazy(() => import("./components/Admin/Social/Social"));
const Banner2 = React.lazy(() => import("./components/Admin/parts/banner2"));
const GroupDetails = React.lazy(() => import("./components/Admin/Components/Tools/Group-Page/GroupDetails"));
const GroupList = React.lazy(() => import("./components/Admin/Components/Tools/Group-Page/GroupList"));
const AddGroupDetails = React.lazy(() => import("./components/Admin/Components/Tools/Group-Page/AddGroupPage"));
const ThankYouMsg = React.lazy(() => import("./components/Helpers/ThankYouMsg"));
const GamifyReviews = React.lazy(() => import("./components/Admin/Components/GamifyReviews/GamifyReviews"));
const PageVisits = React.lazy(() => import("./components/Admin/Components/Tools/Page-Visits/pageVisits"));
const IreviewFooter = React.lazy(() => import("./components/IreviewFooter"));
const OrderMarketingMaterialWrapper = React.lazy(() => import("./components/Admin/Components/Marketing_Materials/OrderMarketingMaterialWrapper"));
const MyCartWrapper = React.lazy(() => import("./components/Admin/Components/Marketing_Materials/Cart/MyCartWrapper"));
const ShippingWrapper = React.lazy(() => import("./components/Admin/Components/Marketing_Materials/Shipping/ShippingWrapper"));
const OrdersWrapper = React.lazy(() => import("./components/Admin/Components/Marketing_Materials/Orders/OrdersWrapper"));
const Order = React.lazy(() => import("./components/Admin/Components/Marketing_Materials/Orders/Order"));
const Downloads = React.lazy(() => import("./components/Admin/Components/Account/Downloads/downloads"));
const CouponListGenerated = React.lazy(() => import("./components/Admin/Components/Tools/CouponsGenerated/CouponsGeneratedList"))
const Platform = React.lazy(() => import("./components/Admin/Components/IncludedPlatforms/Platform"));

const SocialConnections = React.lazy(() => import("./components/Admin/Components/Tools/New_Social/Connections/SocialConnections"));
const SocialSchedules = React.lazy(() => import("./components/Admin/Components/Tools/New_Social/Schedules/Schedules"));
const SocialPublish  = React.lazy(() => import("./components/Admin/Components/Tools/New_Social/Posts/Publish"));
const SocialReviews = React.lazy(() => import("./components/Admin/Components/Tools/New_Social/Reviews/Reviews"));
const SocialNewReview = React.lazy(() => import("./components/Admin/Components/Tools/New_Social/Reviews/NewSchedule"));
const SocialConnectionSettings = React.lazy(() => import("./components/Admin/Components/Tools/New_Social/Connections/ConnectionSettings"));
const Dashboard = React.lazy(() => import("./components/Admin/Components/Tools/New_Social/Dashboard/dashboard"));
const Achievement = React.lazy(() => import("./components/Admin/Components/NextAchievement/Achievement"));

const App = () => {
  const dispatch = useDispatch()

  const impersonateReducer = useSelector(state => state.impersonateReducer.impersonate)
  const demoReducer = useSelector(state => state.demoReducer.demo)
  const cancelReducer = useSelector(state => state.cancelReducer.cancel)


  const [days, setDays] = useState()
  const user = useSelector(state => state.getUserInfo.user)
  const location = useSelector(state => state.locationSelected.location)
  const groupSelected = useSelector(state => state.groupSelected.group)

  useEffect(() => {
    if(location || groupSelected){
      dispatch(clearFetchPermissions())
      dispatch(fetchPermissions(location, groupSelected))
    }
  }, [location, groupSelected])


  //This checks if user location is demo or not
  useEffect(() => {
    if (user) {
      let location_details = user && user.user.locations.filter(loc => loc.id == location)
      if(location === "all"  && user.user.primary_location){
        let locationPrimary = user && user.user.locations.filter(loc => loc.id == user.user.primary_location)
          if(locationPrimary[0]?.is_demo === 1){
            dispatch(checkDemo(true))
            localStorage.setItem("demo", true)
            setDays(locationPrimary[0].demo_days)
          } else {
            dispatch(checkDemo(false))
            localStorage.setItem("demo", false)
          }
      } else {
        if(location_details[0] && location_details[0].is_demo === 1 && location_details[0].acc_status === 1){
          dispatch(checkDemo(true))
          dispatch(checkCancel(false))

          localStorage.setItem("demo", true)
          localStorage.setItem("cancel", false)

          setDays(location_details[0].demo_days)

        } else if (location_details[0] && (location_details[0].acc_status === 0 && location_details[0].is_demo === 0)){
          dispatch(checkDemo(false))
          dispatch(checkCancel(true))

          localStorage.setItem("demo", false)
          localStorage.setItem("cancel", true)
        } else {
          
          dispatch(checkDemo(false))
          dispatch(checkCancel(false))
          localStorage.setItem("demo", false)
          localStorage.setItem("cancel", false)
        }
      }
      if(user.user.role !== "user"){
        window.location.href = "https://accounts.kore.co/login?platform=o4124n1on4c1oc4n124nc1adsdlwoooq"
      }
    }
  }, [user, location])

  return (    
    <div className={`App ${(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) ? "banner" : ""}`}>
      {/* <ScrollToTopCircle /> */}
        <BrowserRouter>
          <ScrollToTop>
            <Routes>
              <Route path="/" element={<HomeOrigin />} />
              <Route path="/mainhome" element={<Home />} />
              <Route path="/create" element={<SignUp />} />
              <Route path="/create/location" element={<LocationForm />} />
              <Route path="/mainhome" element={<Home />} />
              <Route path="/categories/:id" element={<Category />} />
              <Route path="/find/:search_text" element={<FindKeywords />} />
              <Route path="/company/:name" element={<About />} />
              <Route path="/login" element={<Login />} />
              <Route path="/terms" element={ <TermsConditions />}/>
              <Route path="/logout" element={<ProtectedRoute><LogOut /></ProtectedRoute>} />
              <Route path="/accreditation" element={ <AccreditationSignUp />}/>
              <Route path="/flag" element={<Flagging />} />
              {/* <Route path="/locked" element={<Locked />} /> */}
              <Route 
                path="app/admin" 
                element={
                    <Layout 
                        title="Dashboard"
                        permissions={"dashboard"}  
                        all={true} 
                        groups={true} 
                        days={days}
                    >
                      <Admin />
                    </Layout>
                }
              />
              <Route
                path="app/admin/reviews/list"
                element={
                  <Layout
                    title="Manage Reviews"
                    all={true}
                    groups={true}
                    permissions={"manageReviews"}
                  >
                    <Reviews />
                  </Layout>
                }
              />
              <Route
                path="app/admin/autopilot/details"
                element={
                  <Layout
                    title="Details"
                    all={true}
                    groups={true}
                    permissions={"manageAutoPilot"}
                  >
                    <AutopilotAi />
                  </Layout>
                }
              />
              <Route
                path="app/admin/autopilot/settings"
                element={
                  <Layout
                    title="Settings"
                    all={true}
                    groups={true}
                    permissions={"settingsAutoPilot"}
                  >
                    <SettingsAutopilot />
                  </Layout>
                }
              />
              <Route
                path="app/admin/reviews/tags"
                element={
                  <Layout
                    title="Manage Tags"
                    all={true}
                    groups={true}
                    permissions={"manageTags"}
                  >
                    <Tags />
                  </Layout>
                }
              />
              <Route
                path="app/admin/reviews/sources"
                element={
                  <Layout
                    title="Review Sources"
                    all={true}
                    groups={true}
                    permissions={"manageSources"}
                  >
                    <Sources />
                  </Layout>
                }
              />
              <Route
                path="app/admin/reviews/connections"
                element={
                  <Layout
                    title="Connections"
                    all={true}
                    permissions={"manageConnections"}
                  >
                    <Connections />
                  </Layout>
                }
              />
              <Route
                path="app/admin/reviews/connections/:id"
                element={
                  <Layout
                    title="Connections"
                    all={true}
                    disable_location={true}
                    permissions={"manageConnections"}
                  >
                    <ConnectionsSettings />
                  </Layout>
                }
              />
              <Route
                path="app/admin/reviews/list/:id"
                element={
                  <Layout
                    title="Manage Reviews"
                    all={true}
                    permissions={"manageReviews"}
                  >
                    <Review />
                  </Layout>
                }
              />
              <Route
                path="app/admin/tools/coupons"
                element={
                  <Layout
                    title="Coupon Opt-Ins"
                    all={true}
                    permissions={"couponsOptIns"}
                  >
                    <CouponList />
                  </Layout>
                }
              />
              <Route
                path="app/admin/tools/coupons-list"
                element={
                  <Layout
                    title="Coupons List"
                    all={true}
                    permissions={"couponsList"}
                  >
                    <CouponListGenerated />
                  </Layout>
                }
              />
              <Route
                path="/app/admin/tools/review"
                element={
                  <Layout
                    title="Review Page"
                    all={true}
                    groups={true}
                    permissions={"reviewPage"}
                  >
                    <ReviewPage />
                  </Layout>
                }
              />
              <Route
                path="/app/admin/tools/share"
                element={
                  <Layout
                    title="RevuShare - Web"
                    all={true}
                    groups={true}
                    disable_location={true}
                    permissions={"revuShare"}
                  >
                    <RevuShare />
                  </Layout>
                }
              />
              <Route
                path="/app/admin/tools/qrcode"
                element={
                  <Layout
                    title="QRCode"
                    all={true}
                    groups={true}
                    permissions={"qrCode"}
                  >
                    <QrCode />
                  </Layout>
                }
              />
              <Route
                path="/app/admin/tools/group-page"
                element={
                  <Layout
                    title="Group Page"
                    all={true}
                    disable_location={true}
                    permissions={"groupPage"}
                  >
                    <GroupList />
                  </Layout>
                }
              />
              <Route
                path="/app/admin/tools/group-page/add"
                element={
                  <Layout
                    title="Group Page"
                    all={true}
                    disable_location={true}
                    permissions={"groupPage"}
                  >
                    <AddGroupDetails />
                  </Layout>
                }
              />
              <Route
                path="/app/admin/tools/group-page/:id"
                element={
                  <Layout
                    title="Group Page"
                    all={true}
                    disable_location={true}
                    permissions={"groupPage"}
                  >
                    <GroupPage />
                  </Layout>
                }
              />
              <Route
                path="/app/admin/tools/quick-replies"
                element={
                  <Layout
                    title="Quick Replies"
                    all={true}
                    disable_location={true}
                    permissions={"quickReplies"}
                  >
                    <QuickReplies />
                  </Layout>
                }
              />
              <Route
                path="/app/admin/tools/page-visits"
                element={
                  <Layout
                    title="Page Visits"
                    all={true}
                    groups={true}
                    disable_location={false}
                    permissions={"pageVisits"}
                  >
                    <PageVisits />
                  </Layout>
                }
              />
              <Route
                path="/app/admin/gamify"
                element={
                  <Layout
                    title="Gamify Reviews"
                    all={false}
                    disable_location={false}
                    permissions={"gamifyReviews"}
                  >
                    <GamifyReviews />
                  </Layout>
                }
              />
              <Route
                path="/app/admin/support"
                element={
                  <Layout
                    title="Support"
                    all={true}
                    disable_location={true}
                    permissions={"support"}
                  >
                    <Support />
                  </Layout>
                }
              />
              <Route
                path="/app/admin/locations/reputation"
                element={
                  <Layout
                    title="Reputation"
                    all={true}
                    groups={true}
                    permissions={"reputation"}
                  >
                    <Reputation />
                  </Layout>
                }
              />
              <Route
                path="/app/admin/locations/listing"
                element={
                  <Layout
                    title="Listing"
                    all={true}
                    groups={true}
                    permissions={"locationListings"}
                  >
                    <Listing />
                  </Layout>
                }
              />
              <Route
                path="/app/admin/locations/listing/:id"
                element={
                  <Layout
                    title="Listing"
                    all={true}
                    permissions={"locationListings"}
                  >
                    <SourceScore />
                  </Layout>
                }
              />
              <Route
                path="/app/admin/locations/information"
                element={
                  <Layout
                    title="Location Information"
                    all={true}
                    disable_location={true}
                    permissions={"locationsInformation"}
                  >
                    <LocationSettings />
                  </Layout>
                }
              />
              <Route
                path="/app/admin/locations/information/:id"
                element={
                  <Layout
                    title="Location Information"
                    all={true}
                    disable_location={true}
                    permissions={"locationsInformation"}
                  >
                    <LocationSettingsEdit />
                  </Layout>
                }
              />
              <Route
                path="/app/admin/reports/simple"
                element={
                  <Layout
                    title="Analysis Report"
                    all={true}
                    groups={true}
                    permissions={"analysis_report"}
                  >
                    <AnalysisReport />
                  </Layout>
                }
              />
              <Route 
                path="/app/admin/reports/advanced"
                element={
                  <Layout
                    title="New Analysis Report"
                    all={true}
                    groups={true}
                    permissions={"analysis_report"}
                  >
                    <NewAnalysisReport /> 
                  </Layout>
                }
              />
              <Route 
                path="/app/admin/reports/advanced2"
                element={
                  <Layout
                    title="New Analysis Report"
                    all={true}
                    groups={true}
                    permissions={"analysis_report"}
                  >
                    <NewAnalysisReport2 /> 
                  </Layout>
                }
              />
              <Route 
                path="/app/admin/reports/achievement"
                element={
                  <Layout
                    title="Achievement Report"
                    all={true}
                    groups={true}
                    permissions={"achievement_report"}
                  >
                    <Achievement /> 
                  </Layout>
                }
              />
              <Route
                path="/app/admin/competitor_analysis"
                element={
                  <Layout
                    title="Competitor Analysis"
                    all={true}
                    groups={true}
                    permissions={"competitor_analysis"}
                  >
                    <Competitor />
                  </Layout>
                }
              />
              <Route path="/dashboard/frame" element={<Frame />} />

              <Route path="/forms/:id" element={<Form />} />

              <Route
                path="/app/admin/goto/forms"
                element={
                  <Layout
                    title="Forms & Surveys"
                    all={true}
                    disable_location={true}
                    permissions={"formsSurveys"}
                  >
                    <Forms />
                  </Layout>
                }
              />
              <Route
                path="/app/admin/forms"
                element={
                  <>
                    <Forms />
                    <IreviewFooter />
                  </>
                }
              />
              <Route
                path="/app/admin/forms/:id"
                element={
                  <>
                    <FormDetails />
                    <IreviewFooter />
                  </>
                }
              />
              <Route
                path="/app/admin/goto/forms/:id"
                element={
                  <Layout
                    title="Form Details"
                    all={true}
                    disable_location={true}
                    permissions={"formsSurveys"}
                  >
                    <FormDetails />
                  </Layout>
                }
              />
              <Route
                path="/app/admin/marketing_materials"
                element={
                  <Layout
                    title="Marketing Materials"
                    all={true}
                    groups={true}
                    permissions={"marketingMaterials"}
                  >
                    <OrderMarketingMaterialWrapper />
                  </Layout>
                }
              />
              <Route
                path="/app/admin/marketing_materials/cart"
                element={
                  <Layout
                    title="Marketing Materials Cart"
                    all={true}
                    groups={true}
                  >
                    <MyCartWrapper />
                  </Layout>
                }
              />
            <Route
              path="/app/admin/marketing_materials/shipping"
              element={
                <Layout
                  title="Marketing Materials Shipping"
                  all={true}
                  groups={true}
                  permissions={"shippingPermissions"}
                >
                  <ShippingWrapper />
                </Layout>
              }
            />
            <Route
              path="/app/admin/marketing_materials/orders"
              element={
                <Layout
                  title="Marketing Materials Orders"
                  all={true}
                  groups={true}
                  permissions={"ordersPermissions"}
                >
                  <OrdersWrapper />
                </Layout>
              }
            />
            <Route
              path="/app/admin/marketing_materials/orders/:id"
              element={
                <Layout
                  title="Marketing Materials Order"
                  all={true}
                  groups={true}
                  permissions={"orderPermissions"}
                >
                  <Order />
                </Layout>
              }
            />
            <Route
              path="app/admin/account/settings"
              element={
                <Layout
                  title="My Account"
                  all={true}
                  disable_location={true}
                  permissions={"myAccount"}
                >
                  <Account />
                </Layout>
              }
            />
            <Route path="/login/admin/account/settings" element={<TokenControl />} />

            <Route
              path="app/admin/account/management"
              element={
                <Layout
                  title="User Management"
                  all={true}
                  groups={true}
                  permissions={"userManagement"}
                >
                  <UserManagement />
                </Layout>
              }
            />
            <Route
              path="app/admin/account/management/:id"
              element={
                <Layout
                  title="User Management"
                  all={true}
                  groups={true}
                  disable_location={true}
                  permissions={"userManagement"}
                >
                  <AccountAddUser />
                </Layout>
              }
            />
            <Route
              path="app/admin/account/management/addUser"
              element={
                <Layout
                  title="Add User"
                  all={true}
                  groups={true}
                  disable_location={true}
                  permissions={"userManagement"}
                >
                  <AddUser />
                </Layout>
              }
            />
            <Route path="/login/admin/account/addUser" element={<TokenControl />} />

            <Route
              path="app/admin/account/notifications"
              element={
                <Layout
                  title="Notifications"
                  all={true}
                  groups={true}
                  permissions={"notifications"}
                >
                  <Notifications />
                </Layout>
              }
            />
            <Route
              path="app/admin/account/invoices"
              element={
                <Layout
                  title="Invoices"
                  permissions={"invoices"}
                >
                  <Invoices />
                </Layout>
              }
            />
            <Route
              path="app/admin/account/downloads"
              element={
                <Layout
                  title="Downloads"
                  all={true}
                  disable_location={true}
                  permissions={"downloads"}
                >
                  <Downloads />
                </Layout>
              }
            />
            <Route
              path="app/admin/subscription"
              element={
                <Layout
                  title="Subscription"
                  all={true}
                  disable_location={true}
                  permissions={"subscription"}
                >
                  <Subscription />
                </Layout>
              }
            />
            {/* <Route
              path="/app/admin/goto/:id"
              element={
                <Layout
                  title="Social"
                  all={true}
                  disable_location={true}
                  permissions={"social_automation"}
                >
                  <Social />
                </Layout>
              }
            /> */}
            <Route
              path="/app/admin/goto/dashboard"
              element={
                <Layout
                  title="Social Media Stats"
                  all={true}
                  disable_location={true}
                  permissions={"social_automation"}
                >
                  <SocialMediaStats />
                </Layout>
              }
            />
            <Route
              path="/app/admin/goto/reviews"
              element={
                <Layout
                  title="Schedule Reviews"
                  all={true}
                  disable_location={true}
                  permissions={"social_automation"}
                >
                  <ScheduleReviews />
                </Layout>
              }
            />
            <Route
              path="/app/admin/goto/post"
              element={
                <Layout
                  title="Publish To Accounts"
                  all={true}
                  disable_location={true}
                  permissions={"social_automation"}
                >
                  <PublishToAccounts />
                </Layout>
              }
            />
            <Route
              path="/app/admin/goto/schedules"
              element={
                <Layout
                  title="Schedules"
                  all={true}
                  disable_location={true}
                  permissions={"social_automation"}
                >
                  <Schedules />
                </Layout>
              }
            />
            <Route
              path="/app/admin/goto/account_manager"
              element={
                <Layout
                  title="Connections"
                  all={true}
                  disable_location={true}
                  permissions={"social_automation"}
                >
                  <ConnectionsSocial />
                </Layout>
              }
            />
            <Route
              path="/app/admin/social_automation/account_manager"
              element={
                <Layout
                  title="Connections"
                  all={true}
                  disable_location={true}
                  permissions={"social_automation"}
                >
                  <SocialConnections />
                </Layout>
              }
            />
            <Route
              path="/app/admin/social_automation/account_manager/facebook/pages"
              element={
                <Layout
                  title="Facebook Pages"
                  all={true}
                  disable_location={true}
                  permissions={"social_automation"}
                >
                  <SocialConnectionSettings id={"facebook"}/> 
                </Layout>
              }
            />
            <Route
              path="/app/admin/social_automation/account_manager/instagram/accounts"
              element={
                <Layout
                  title="Instagram Accounts"
                  all={true}
                  disable_location={true}
                  permissions={"social_automation"}
                >
                  <SocialConnectionSettings id={"instagram"}/> 
                </Layout>
              }
            />
            <Route
              path="/app/admin/social_automation/schedules"
              element={
                <Layout
                  title="Schedules"
                  all={true}
                  disable_location={true}
                  permissions={"social_automation"}
                >
                  <SocialSchedules />
                </Layout>
              }
            />
            <Route
              path="/app/admin/social_automation/dashboard"
              element={
                <Layout
                  title="Dashboard"
                  all={true}
                  disable_location={true}
                  permissions={"social_automation"}
                >
                  <Dashboard />
                </Layout>
              }
            />
            <Route
              path="/app/admin/social_automation/post"
              element={
                <Layout
                  title="Publish To Accounts"
                  all={true}
                  disable_location={true}
                  permissions={"social_automation"}
                >
                  <SocialPublish />
                </Layout>
              }
            />
            <Route
              path="/app/admin/social_automation/reviews"
              element={
                <Layout
                  title="Publish To Accounts"
                  all={true}
                  disable_location={true}
                  permissions={"social_automation"}
                >
                  <SocialReviews />
                </Layout>
              }
            />
            <Route
              path="/app/admin/social_automation/reviews/new"
              element={
                <Layout
                  title="Publish To Accounts"
                  all={true}
                  disable_location={true}
                  permissions={"social_automation"}
                >
                  <SocialNewReview />
                </Layout>
              }
            />

            <Route
              path="/app/admin/goto/:id"
              element={
                <Layout
                  title="included_platforms"
                  all={true}
                  disable_location={true}
                  permissions={"included_platform"}
                >
                  <Platform />
                </Layout>
              }
            />

              <Route path="/login/admin/account/notifications" element={<TokenControl />} />
              <Route path="/thank-you" element={<ThankYou />} />
              {/* <Route path="/thank-you" element={<ThankYouMsg />} /> */}

              <Route path="/admin/login" element={<TokenControl />} />

              <Route path="/login/admin" element={<TokenControl />} />
              <Route path="/login/admin/reviews" element={<TokenControl />} />
              <Route path="/login/admin/reviews/list/:id" element={<TokenControl />} />
              <Route path="/login/admin/reviews/tags" element={<TokenControl />} />
              <Route path="/login/admin/reviews/sources" element={<TokenControl />} />

              <Route path="/login/admin/locations/reputation" element={<TokenControl />} />
              <Route path="/login/admin/location_information" element={<TokenControl />} />
              <Route path="/login/admin/locations/listing" element={<TokenControl />} />
              <Route path="/login/admin/locations/listing/:id" element={<TokenControl />} />

              <Route path="/login/admin/reviews/sources" element={<TokenControl />} />

              <Route path="/login/admin/analysis_report" element={<TokenControl />} />

              <Route path="/login/admin/tools/review" element={<TokenControl />} />
              <Route path="/login/admin/account/management" element={<TokenControl />} />
              <Route path="/login/admin/subscription" element={<TokenControl />} />

              <Route path="/401" element={<UnAuthorize />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </ScrollToTop>
        </BrowserRouter>
    </div>
  );
}

export default App;
