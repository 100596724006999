import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import WalletDetails from "./wallet_details"
import { CircularProgress } from "@mui/material"
import { useTranslation } from "react-i18next"
import { fetchCards } from "../../../../redux/actions/admin-index"
import PaymentCard from "../../Components/PaymentCard/PaymentCard"
import { useDispatch } from "react-redux"

const WalletBody = ({ setOpen, customerId, open }) => {
    const dispatch = useDispatch()
    const [hasWallet, setHasWallet] = useState(true)
    const wallet = useSelector(state => state.getWalletReducer.wallet)
    const { t } = useTranslation()
    const [errors, setErrors] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (wallet) {
            if (wallet.cards) {
                setHasWallet(true)
            } else {
                setHasWallet(false)
            }
        }
    }, [wallet])

    const style = {
        div: {
            background: "#ffff",
            minWidth: "fit-content",
            padding: "20px",
            width: "400px",
            position: "fixed",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            height: "fit-content",
            margin: "auto",
            borderRadius: "5px",
            textAlign: "center"
        },
        h4: {
            fontFamily: "Poppins-Regular",
            fontSize: "14px"
        },
        progress: {
            opacity: '0.1',
        }
    }

    const onSave = () => {
        dispatch(fetchCards())
        setHasWallet(true)
        setLoading(false)
    }


    const onSaveError = (err) => {
        setLoading(false)
        err.response.data.errors ?
            Object.keys(err.response.data.errors).map(key => {
                setErrors(errors => [...errors, err.response.data.errors[key][0]])
            })
            :
            setErrors(["Something Went Wrong"])
        setTimeout(() => {
            setErrors([])
        }, 5000)
    }

    if (wallet) {
        return hasWallet ?
            <WalletDetails setOpen={setOpen} onSaveError={onSaveError}/> :
            <PaymentCard
                open={open}
                setOpen={setOpen}
                onSave={onSave}
                onSaveError={onSaveError}
                loading={loading}
                setLoading={setLoading}
            />
    } else {
        return (
            <div style={style.div}>
                <h4 style={style.h4}>{t("Please Wait")}</h4>
                <CircularProgress color="inherit" style={style.progress} />
            </div>
        )
    }

}
export default WalletBody