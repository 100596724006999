import React, {useRef, useState, useEffect} from "react"
import { Select, FormControl, MenuItem, Collapse, CircularProgress } from "@mui/material"
import { MdClear } from "react-icons/md"
import { useTranslation } from "react-i18next"
import { MySelectSmall } from "../../../Helpers/MySelectShadow"
import { countries } from "../../../Helpers/countries"
import { americaStates, mexicoStates, canadaStates } from "../../../Helpers/states"
import { HiOutlineArrowNarrowRight } from "react-icons/hi"
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md"
import { getCardImage } from "../../helpers/getCardTypeImage"
import { formatCardNumber } from "../../helpers/formatCard"
import { FlagIcon } from "react-flag-kit"
import { LoadingButton } from "@mui/lab"
import axios from "axios"
import { getLocalUser } from "../../../TokenControl/parts/useLocalUser"
import { baseUrlDev } from "../../../Helpers/baseUrl"
import { useDispatch } from "react-redux"
import { DeleteCard, EditCard, AddCard } from "../../../../redux/actions/admin-index"

const card_types = [
    { value: "4", label: "Visa" },
    { value: "5", label: "Mastercard" },
    { value: "3", label: "Amex" },
    { value: "6", label: "Discover" },
    { value: "5", label: "Maestro" },
]

const Card = ({
    cards, 
    onSave, 
    onEdit, 
    onDelete, 
    onSaveError, 
    selected, 
    loading, 
    setOpen, 
    setLoading, 
    show_cards,
}) => {
    const dispatch = useDispatch()
    const yearRef = useRef()
    const sliderCardRef = useRef()
    const cardsRef = useRef([]);
    const [t] = useTranslation()
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(true);
    const [errors, setErrors] = useState([])
    const [card_type, setCardType] = useState("Uknown")
    const [selectedCard, setSelectedCard] = useState("")
    const [allFieldsFilled, setAllFieldFilled] = useState(false)
    const [deleteCardLoading, setDeleteCardLoading] = useState(false)
    const [input_err, setInputErr] = useState([])
    const [triggerApi, setTriggerApi] = useState(false)

    const [cardDetails, setCardDetails] = useState({
        name: "",
        card_number: "",
        expiry_month: "",
        expiry_year: "",
        cvc: "",
        address: '',
        city: "",
        state: "",
        country: "US",
        zip_code: ""
    })

    useEffect(() => {
        if(selected){
            let ind = cards?.findIndex(item => item.id == selected);
            setSelectedCard(selected)
            onCardLoad(ind)
        }
    }, [selected])

    useEffect(() => {
        if (!cards) return;
        cardsRef.current = cards?.map((_, i) => cardsRef.current[i] ?? React.createRef());
      }, [cards]);

    useEffect(() => {
        checkCartComplete()
        validate()
    }, [cardDetails])

    //IF A CARD IS SELECTED THAN SET THE CARD DETAILS
    useEffect(() => {
        let card = cards?.find(c => c.id == selectedCard)
        if(card){
            setCardDetails(
                {...cardDetails, 
                    id: card?.id,
                    name: card?.name_on_card, 
                    card_number: `**** **** **** ${card.last_four_numbers}`,
                    expiry_month: card?.expiration_month,
                    expiry_year: card?.expiration_year,
                    address: card?.street,
                    city: card?.city,
                    state: card?.state,
                    country: card?.country,
                    zip_code: card?.zip_code
                })
        }
    }, [selectedCard])

// HANDLE CHANGE OF CVV
    const handleCvvNumber = (e) => {
        const { name, value } = e.target
        if (value.length <= 4) {
            setCardDetails({ ...cardDetails, [name]: value })
        } else {
            return false
        }
    }

// HANDLE CHANGE OF CARD NUMBER INPUT      
    const handleCardNumber = (e) => {
        const { name, value } = e.target
        setCardType(value.length >= 1 ? (card_types.find(it => it.value == value[0])?.label || "Unknown") : "Unknown");
        if (value.length <= 19 && e.target.validity.valid) {
            setCardDetails({ ...cardDetails, [name]: value })
        } else {
            return false
        }
    }
//HANDLE CHANGE OF INPUTS ON CARD
    const handleChange = (e) => {
        setErrors([])
        const { name, value } = e.target
        switch (name) {
            case "month":
                if (value.length === 2) {
                    yearRef.current.focus();
                }
                setCardDetails({ ...cardDetails, [name]: value.replace(/\D/g, '') });
                break;
            case "year":
                setCardDetails({ ...cardDetails, [name]: value.replace(/\D/g, '') });
                break;
            case "country":
                if (["US", "CA", "MX"].includes(value)) {
                    setCardDetails({ ...cardDetails, [name]: value });
                } else {
                    setCardDetails({ ...cardDetails, [name]: value, state: "" });
                }
                break;
            default:
                setCardDetails({ ...cardDetails, [name]: value });
                break;
        }
    }
//SCROLL TO THE LEFT OF CARD LIST
    const handleScrollLeft = () => {
        if (sliderCardRef.current) {
            sliderCardRef.current.scrollBy({ left: -164, behavior: 'smooth' });
        }
    };
//SCROLL TO THE RIGHT OF CARD LIST
    const handleScrollRight = () => {
        if (sliderCardRef.current) {
            sliderCardRef.current.scrollBy({ left: 164, behavior: 'smooth' });
        }
    };
//FUNCTION TO CHECK ARROWS
    const handleScroll = () => {
        if (sliderCardRef.current) {
          const { scrollLeft, scrollWidth, clientWidth } = sliderCardRef.current;
          setShowLeftArrow(scrollLeft > 0);
          setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
        }
    };

//ON INITIAL LOAD ADD HANDLESCROLL FUNCTION
    useEffect(() => {
        const slider = sliderCardRef.current;
        if (slider) {
          slider.addEventListener('scroll', handleScroll);
        }
        return () => slider && slider.removeEventListener('scroll', handleScroll);
    }, []);

//ON CLICK OF CARD SCROLL TO THE SELECTED CARD
    const onCardClick = (index) => {
        const box = sliderCardRef?.current?.children[index];
        if(box){
            sliderCardRef.current.scrollTo({
                left: box.offsetLeft - 60,
                behavior: 'smooth',
            });
        }
    }
//ON OPEN MOADL SCROLL TO THE SELECTED CARD
    const onCardLoad = (index) => {
        const box = sliderCardRef?.current?.children[index];
        if(box){
            setTimeout(() => {
                box.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
            }, 0);
        }
    }
//RESET CARD DETAILS STATE 
    const resetCardDetails = () => {
        setSelectedCard("")
        setCardDetails(prevState => 
          Object.keys(prevState).reduce((acc, key) => {
            acc[key] = key === "country" ? "US" : "";
            return acc;
          }, {})
        );
    };
//FUNCTION TO CHECK IF INPUTS ARE FILLED TO MAKE BUTTON DISABLED
    const checkCartComplete = () => {
        const isCountryWithStateRequired = ["us", "mx", "ca"].includes(cardDetails.country.toLowerCase());
    
        const valuesToCheck = Object.entries(cardDetails)
            .filter(([key, value]) => {
                return key !== 'id' && (key !== 'state' || isCountryWithStateRequired);
            })
            .map(([, value]) => value);
    
        if (valuesToCheck.every(value => value?.trim() !== "")) {
            setAllFieldFilled(true);
        } else {
            setAllFieldFilled(false);
        }
    };
     

//VALIDATE INPUTS FUNCTION
    const validate = () => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1;
        const errors = [];
    
        Object.keys(cardDetails).forEach(key => {
            if (!cardDetails[key]) {
                if (key === "state" && ["us", "mx", "ca"].includes(cardDetails.country.toLowerCase())) {
                    errors.push({ [key]: `${key} is required` });
                } else if ((key !== "state") && key !== "id") {
                    errors.push({ [key]: `${key} is required` });
                }
            } else {
                if (key === "card_number" && cardDetails[key].length < 19) {
                    errors.push({ "card_number": `Please enter a 16-digit card number` });
                } else if (key === "expiry_month") {
                    if (Number(cardDetails.expiry_month) > 12) {
                        errors.push({ "expiry_month": `Please enter a valid month (1-12)` });
                    } else if (
                        Number(cardDetails.expiry_year) === currentYear &&
                        Number(cardDetails.expiry_month) < currentMonth
                    ) {
                        errors.push({ "expiry_month": `The expiration date cannot be in the past` });
                    }
                } else if (key === "expiry_year") {
                    if (!/^\d{4}$/.test(cardDetails.expiry_year)) {
                        errors.push({ "expiry_year": `Expiration year must be a 4-digit number` });
                    } else if (Number(cardDetails.expiry_year) < currentYear) {
                        errors.push({ "expiry_year": `The expiration year cannot be in the past` });
                    }
                } else if (key === "cvc" && cardDetails.cvc.length < 3) {
                    errors.push({ "cvv": `CVV must be at least 3 digits` });
                }
            }
        });   
        setInputErr(errors);
    };
    
    
//SAVE CARD FUNCTION
    const saveCard = () => {
        setLoading(true)
        setTriggerApi(true)
        let data = {
            name: cardDetails.name,
            number: cardDetails.card_number,
            expiry: `${cardDetails.expiry_month}/${cardDetails.expiry_year}`,
            cvv: cardDetails.cvc,
            street: cardDetails.address,
            city: cardDetails.city,
            state: cardDetails.state,
            country: cardDetails.country,
            zip_code: cardDetails.zip_code
        }
        if(input_err.length == 0){
            axios.post(`${baseUrlDev}wallet/save-card`, data, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })
                .then((res) => {
                    let card = res.data.card
                    onSave(card)
                    setTriggerApi(false)
                })
                .catch((err) => {
                    onSaveError(err)
                    setTriggerApi(false)
                })
        } else {
           setTimeout(() => {
            setLoading(false)
           }, 100)
        }
    }
//DELETE CARD FUNCTION
    const deleteCard = (id) => {
        setDeleteCardLoading(true);
        axios.post(`${baseUrlDev}subscription/delete/card`, { card_id: id}, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })
            .then((res) => {
                if (res.data.status) {
                   dispatch(DeleteCard(id))
                   setSelectedCard("")
                   onDelete()
                } else {
                    setErrors(prev => [...prev, res.data.message])
                    setTimeout(() => {
                        setErrors([])
                    }, 5000)
                }
                setDeleteCardLoading(false);
            })
            .catch((err) => {
                setDeleteCardLoading(false);
                Object.keys(err?.response?.data?.errors)?.map(key => {
                    setErrors([...errors, err?.response?.data?.errors[key][0]])
                })
                setTimeout(() => {
                    setErrors([])
                }, 5000)
            })
            .finally(() => {
                setDeleteCardLoading(false);
            })
    }

//EDIT CARD FUNCTION
    const editCard = () => {
        setLoading(true)
        setTriggerApi(true)
        const data = {
            ...(cardDetails.id && { hash_id: cardDetails.id }), 
            card_number: cardDetails.card_number.includes("****") ? cardDetails.card_number.slice(-4) : cardDetails.card_number, 
            city: cardDetails.city,
            country: cardDetails.country,
            cvv: cardDetails.cvc,
            expiration_month: cardDetails.expiry_month,
            expiration_year: cardDetails.expiry_year,
            name_on_card: cardDetails.name,
            state: cardDetails.state,
            street: cardDetails.address,
            zip_code: cardDetails.zip_code
        }
        if(input_err.length == 0){
            axios.post(`${baseUrlDev}subscription/update/card`, data, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })
            .then((res) => {
                let card = res?.data?.card
                dispatch(EditCard(card))
                onEdit(card)
                setTriggerApi(false)
            })
        } else {
            setLoading(false) 
        }

    }

    return(
        <div className="wrapper-loc-edit-cart">
            <div className="cart-details-container">
                <div className="cart-header-wrapper">
                    <img src="/admin-icons/kore.png" alt="Kore" />
                    <button onClick={() => setOpen()}><MdClear /></button>
                </div>
                <div className="cart-details-body">
                    {(cards?.length > 0 && show_cards) && <div className="card-list-container">
                        {showLeftArrow && <div className="sliding-button" onClick={handleScrollLeft}><MdKeyboardArrowLeft /></div>}
                        <div className="card-list-wrapper" ref={sliderCardRef}>
                            {cards?.map((card, i) => {
                                return(
                                    <div className="card-list-el" key={`card-item-${i}`}>
                                        <input 
                                            id={`x${card.id}`} 
                                            value={card.id} 
                                            type="radio" 
                                            name="card-item" 
                                            onChange={(e) => setSelectedCard(e.target.value)}
                                            checked={card.id == selectedCard}
                                        />
                                        <label htmlFor={`x${card.id}`} onClick={() => onCardClick(i)}>
                                            <span></span>
                                            <p>{`x${card.last_four_numbers}`} <span>{card.expiration_month}/{card.expiration_year}</span></p>
                                            <img src={getCardImage(card.card_type)} alt="visa"/>
                                        </label>
                                    </div>
                                )
                            })}
                        </div>
                        {(showRightArrow && cards.length >= 3) && <div className="sliding-button" onClick={handleScrollRight}><MdKeyboardArrowRight /></div>}
                        <div>
                            <button className="card-add-item" onClick={() => resetCardDetails()}>Add Card</button>
                        </div>
                    </div>}
                    <div className="card_details_wrapper custom-scrollbar-vertical">
                       <h6>Enter Your Credit Card Information</h6>
                       <div className="cart-form-inputs" style={{flex: 1}}>
                                <label>{t("Full Name*")}</label>
                                <input
                                    type="text"
                                    name="name"
                                    value={cardDetails.name}
                                    onChange={(e) => handleChange(e)}
                                />
                                {triggerApi && <span className="card_error">{input_err.find(el => el.hasOwnProperty("name"))?.name}</span>}
                            </div>
                        <div className="cart-form-inputs">
                            <label>{t("Card Number")}</label>
                            <input
                                data-card={card_type}
                                placeholder="1244 1244 1244 1244"
                                maxLength="19"
                                className="card-number-img"
                                type="text"
                                disabled={selectedCard}
                                name="card_number"
                                value={cardDetails.card_number && formatCardNumber(cardDetails.card_number)}
                                onChange={handleCardNumber}
                                pattern="[0-9\s]*"
                              
                            />
                            {triggerApi && <span className="card_error">{input_err.find(el => el.hasOwnProperty("card_number"))?.card_number}</span>}
                        </div>
                        <div className="cart-info-details">
                            <div>
                                <label>{t("Expiry Date")}</label>
                                <div className="cart-expiry-details">
                                    <div className="cart-form-inputs" style={{marginBottom: 0}}>
                                        <input
                                            type="text"
                                            pattern="[0-9\s]*"
                                            name="expiry_month"
                                            placeholder="MM"
                                            maxLength="2"
                                            value={cardDetails.expiry_month}
                                            onChange={(e) => handleChange(e)}
                                        />
                                    </div>
                                    <div className="cart-form-inputs" style={{marginBottom: 0}}>
                                        <input
                                            type="text"
                                            pattern="[0-9\s]*"
                                            name="expiry_year"
                                            ref={yearRef}
                                            maxLength="4"
                                            placeholder="YY"
                                            value={cardDetails.expiry_year}
                                            onChange={(e) => handleChange(e)}
                                        />
                                    </div>
                                </div>
                                {triggerApi && <span className="card_error">{input_err.find(el => el.hasOwnProperty("expiry_month"))?.expiry_month}</span>}
                                {triggerApi && <span className="card_error">{input_err.find(el => el.hasOwnProperty("expiry_year"))?.expiry_year}</span>}
                            </div>
                            <div className="cart-form-inputs">
                                <label>Security Code</label>
                                <div className="security_code_inp">
                                    <img src="/admin-icons/credit-card-cvc.svg" alt="credit_Card" />
                                    <input
                                        type="number"
                                        name="cvc"
                                        placeholder={selectedCard ? "***" : "CVV/CVV"}
                                        maxLength={4}
                                        value={cardDetails.cvc}
                                        onChange={(e) => handleCvvNumber(e)}
                                    />
                                    {triggerApi && <span className="card_error">{input_err.find(el => el.hasOwnProperty("cvv"))?.cvv}</span>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="collapsing-wrapper">
                        <h6>{t("Billing Address")}</h6>
                        <div className="cart-form-inputs">
                            <label>{t("Address")}</label>
                            <input
                                type="text"
                                name="address"
                                placeholder="Enter your address"
                                value={cardDetails.address}
                                onChange={(e) => handleChange(e)}
                            />
                            {triggerApi && <span className="card_error">{input_err.find(el => el.hasOwnProperty("address"))?.address}</span>}
                        </div>
                        <div className="cart-info-details">
                            <div className="cart-form-inputs">
                                <label>{t("City")}</label>
                                <input
                                    type="text"
                                    name="city"
                                    placeholder="Enter your City"
                                    value={cardDetails.city}
                                    onChange={(e) => handleChange(e)}
                                />
                                {triggerApi && <span className="card_error">{input_err.find(el => el.hasOwnProperty("city"))?.city}</span>}
                            </div>
                            <div className="cart-form-inputs">
                                <label>{t("State")}</label>
                                {cardDetails.country !== "US" && cardDetails.country !== "CA" && cardDetails.country !== "MX" ?
                                    <input
                                        name="state"
                                        type="text"
                                        placeholder='Select State'
                                        onChange={(e) => handleChange(e)}
                                        value={cardDetails.state}
                                    />
                                    :
                                    <FormControl fullWidth>
                                        <Select
                                            id="input-type-one-select"
                                            label="position"
                                            value={cardDetails.state || ""}
                                            name="state"
                                            input={<MySelectSmall/>}
                                            displayEmpty
                                            renderValue={(value) => (cardDetails.state === "" ? 'Select State' : value)}
                                            MenuProps={{
                                                PaperProps: { sx: { maxHeight: 300 } },
                                                style: {
                                                    width: "100%",
                                                },
                                            }}
                                            onChange={(e) => handleChange(e)}
                                        >
                                            <MenuItem disabled value="">{t("Select State")}</MenuItem>
                                            {cardDetails.country === "US" ?
                                                americaStates.map(state => {
                                                    return (
                                                        <MenuItem key={`state${state.name}`} value={state.code2A}>{state.name}</MenuItem>
                                                    )
                                                })
                                                : cardDetails.country === "CA" ?
                                                    canadaStates.map(state => {
                                                        return (
                                                            <MenuItem key={`state${state.name}`} value={state.code2A}>{state.name}</MenuItem>
                                                        )
                                                    })
                                                : cardDetails.country === "MX" &&
                                                    mexicoStates.map(state => {
                                                        return (
                                                            <MenuItem key={`state${state.name}`} value={state.code2A}>{state.name}</MenuItem>
                                                        )
                                                    })
                                            }
                                        </Select>
                                    </FormControl>
                                    }
                                    {triggerApi && <span className="card_error">{input_err.find(el => el.hasOwnProperty("state"))?.state}</span>}
                            </div>
                        </div>
                        <div className="cart-info-details">
                            <div className="cart-form-inputs">
                                <label>{t("Country")}</label>
                                <FormControl fullWidth>
                                    <Select
                                        id="input-type-one-select"
                                        label="position"
                                        name="country"
                                        value={cardDetails.country}
                                        input={<MySelectSmall/>}
                                        MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                                        onChange={(e) => handleChange(e)}
                                    >
                                        <MenuItem value={" "}><em>None</em></MenuItem>
                                        {countries.map(country => {
                                            return (
                                                <MenuItem key={country.code3A} value={country.code2A}>
                                                    {country.code2A === "US" ? <img src={"/admin-icons/usa.png"} alt={"USA"} style={{ width: "20px", height: "15px", marginRight: "10px" }} /> :
                                                        <FlagIcon code={country.code2A} style={{ width: "17px", height: "15px", marginRight: "4px" }} />}
                                                    {country.name}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                                {triggerApi && <span className="card_error">{input_err.find(el => el.hasOwnProperty("country"))?.country}</span>}
                            </div>
                            <div className="cart-form-inputs">
                                <label>Zip Code</label>
                                <input
                                    type="text"
                                    name="zip_code"
                                    value={cardDetails?.zip_code}
                                    placeholder="Enter your Zip Code"
                                    onChange={(e) => handleChange(e)}
                                />
                                {triggerApi && <span className="card_error">{input_err.find(el => el.hasOwnProperty("zip_code"))?.zip_code}</span>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-details-footer">
                    <div className="buttons-wrapper">
                        {/* {
                            selectedCard && 
                            <LoadingButton
                                style={{ paddingLeft: deleteCardLoading && "30px" }}
                                className="remove-btn"
                                onClick={() => deleteCard(selectedCard)}
                                loadingIndicator={t("Deleting") + "..."}
                                loading={deleteCardLoading}
                            >
                                {!deleteCardLoading && <div className="d-flex"><img src="/admin-icons/trash-red-icon.svg" alt="remove" style={{ marginRight: '8px' }} /> {t("Remove Card")}</div>}
                            </LoadingButton>
                        } */}
                        {selectedCard ? 
                              <button className={`save-btn ${allFieldsFilled ? 'blue' : ""}`} onClick={() => editCard()} disabled={!allFieldsFilled || loading}>
                                {loading ? (
                                    <>{'Confirming...'} <CircularProgress style={{ marginRight: 10, color: "#fff", width: 12, height: 12 }} /></>
                                    ) : (
                                    <>
                                        Confirm
                                        <HiOutlineArrowNarrowRight />
                                    </>
                                )}
                            </button>
                            :
                            <button className={`save-btn ${allFieldsFilled ? 'blue' : ""}`} onClick={() => saveCard()} disabled={!allFieldsFilled || loading}>
                                {loading ? (
                                    <>{'Save...'} <CircularProgress style={{ marginRight: 10, color: "#fff", width: 12, height: 12 }} /></>
                                    ) : (
                                    <>
                                        Save
                                        <HiOutlineArrowNarrowRight />
                                    </>
                                )}
                            </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Card