const initialState = {
    cards: []
}
export const cardsReducer = (state = [] ,action) => {
    switch(action.type){
        case 'GET_CARDS':
            return{
                ...state,
                cards: action.payload
            }
        case 'ADD_CARD': 
            return{
                ...state,
                cards: {
                    ...state.cards,
                    data: [...state.cards.data, action.payload]
                }
            }
        case 'EDIT_CARD':
            return {
                ...state,
                cards: {
                    ...state.cards,
                    data: state.cards.data.map(card =>
                        card.id === action.payload.id
                            ? { ...card, ...action.payload }
                            : card
                    )
                }
            }
        case 'DELETE_CARD':
            return{
                ...state,
                cards: {
                    ...state.cards,
                    data: state.cards.data.filter(card => card.id != action.payload)
                }
            }
        default:
            return{
                ...state
            }
    }
}